import { graphql } from "gatsby";
import React from "react";
import { useGlobalState } from "../../state/globalState";

export default function Datenschutzerklaerung({ data }: any) {
  const { datenschutzerklaerung } = data;

  const [headerOptions, setHeaderOptions] = useGlobalState("headerOptions");

  React.useEffect(() => {
    setHeaderOptions({
      ...headerOptions,
      colorScheme: "dark",
      hidden: true,
    });
  }, []);

  return (
    <>
      <div className="px-2 py-16 sm:px-8">
        <div
          className="prose-sm prose sm:prose"
          dangerouslySetInnerHTML={{ __html: datenschutzerklaerung.html }}
        />
      </div>
    </>
  );
}

export const query = graphql`
  query AppDatenschutzQuery {
    datenschutzerklaerung: markdownRemark(
      frontmatter: { templateKey: { eq: "app-datenschutz" } }
    ) {
      html
    }
  }
`;
